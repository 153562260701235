export const USER_API = process.env.NEXT_PUBLIC_USER_API;
export const USERS = `${USER_API}user/`;
export const USER_PROFILE = `${USER_API}user/profile`;
export const USER_CAREER = `${USER_API}user/profile/career`;
export const USER_ROLE = `${USER_API}user/profile/role`;
export const USER_HANDLE = `${USER_API}user/handle`;
export const ABOUT = `${USER_API}user/profile/about`;
export const WAITING_LIST = `${USER_API}waiting-list/validate/`;
export const SURVEY_API = process.env.NEXT_PUBLIC_SURVEY_API;
export const SURVEYS = `${SURVEY_API}surveys`;
export const SHARED_SURVEYS = `${SURVEY_API}shared/surveys`;
export const TEAMWORK_SKILLS_RESULTS = `${SURVEY_API}teamwork-skill/result`;
export const SKILL_API = process.env.NEXT_PUBLIC_SKILL_API;
export const ROLES_URL = `${SKILL_API}roles`;
export const SKILLS_UPDATE = `${SKILL_API}user/profile/skill`;
export const SKILLS_PROFILE = `${SKILL_API}user/profile`;
export const SKILLS_USER = `${SKILL_API}user/`;
export const SKILLS_LIST = `${SKILL_API}/skills?text=`;
export const INTERESTS_LIST = `${SKILL_API}/interest?text=`;
export const INTERESTS_PROFILE = `${SKILL_API}user/profile`;
export const INTERESTS_UPDATE = `${SKILL_API}user/profile/interest`;
export const PERSONALITY_API = process.env.NEXT_PUBLIC_PERSONALITY_API;
export const PERSONALITY_RESULT = `${PERSONALITY_API}personality`;
export const PERSONALITIES_RESULTS = `${SKILL_API}personalities`;
export const BLOB_IMAGES = process.env.NEXT_PUBLIC_BLOB_IMAGES;
export const DEFAULT_NAMESPACES = ['common', 'components'];
export const TEAMWORK_SURVEY_ID = '641db9a4acb2e11c3855cff4';
export const WORKSTYLE_SURVEY_ID = '641a3dee4c63ab06342f593e';
export const PERSONALITY_SURVEY_ID = '641b6f9f2ac34e1617f92fc0';
export const PERSONALITY_AVATARS =
  'https://huggasastatic.blob.core.windows.net/staticdoc-public/personality-avatars/';
