import { useState } from 'react';
import { useMsal } from '@azure/msal-react';
import type { AccountInfo } from '@azure/msal-browser';

type User = {
  family_name: string;
  given_name: string;
  name: string;
  oid: string;
};

export const useUser = () => {
  const { instance } = useMsal();
  const activeAccount: AccountInfo = instance.getActiveAccount();
  const [user] = useState<User>(activeAccount?.idTokenClaims as User);
  const { family_name: lastName, given_name: name, name: fullName, oid } = user ?? {};
  return { lastName, name, fullName, oid };
};
