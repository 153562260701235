/* eslint-disable no-console */
import { createContext } from 'react';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { useUser } from './hooks';
import { useQueryClient } from '@tanstack/react-query';

export const AuthContext = createContext(
  {} as {
    user: any;
    logOut: () => void;
    logIn: () => void;
    isUpdating: boolean;
  }
);

export const AuthProvider = ({ children }: any) => {
  const { instance, inProgress } = useMsal();
  const isUpdating = inProgress !== InteractionStatus.None;
  const queryClient = useQueryClient();
  const logIn = () => instance.loginRedirect().catch((error) => console.log(error));
  const logOut = () => {
    queryClient.invalidateQueries();
    instance.logoutRedirect().catch((error) => console.log(error));
  };
  const user = useUser();

  return (
    <AuthContext.Provider
      value={{
        user,
        isUpdating,
        logOut,
        logIn,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
