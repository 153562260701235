import { RESET_STEPS, COMPLETE_ACTUAL_STEP, TO_NEXT_STEP, RESET_REGISTER_REDUCER } from './type';

const initialState = {
  actualStep: 1,
  actualStepComplete: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case RESET_STEPS:
      return { ...state, actualStep: 1 };
    case COMPLETE_ACTUAL_STEP:
      return { ...state, actualStepComplete: true };
    case TO_NEXT_STEP:
      return {
        ...state,
        actualStep: state.actualStep + 1,
        actualStepComplete: false,
      };
    case RESET_REGISTER_REDUCER:
      return {
        actualStep: 1,
        actualStepComplete: false,
      };
    default:
      return state;
  }
}
