/* eslint-disable no-console */
import fetchWrapper from './fetch-wrapper';
import { msalInstance } from '@features/Auth/authConfig';
import { SURVEYS } from '@src/constants';

export const TEAMWORK_SURVEY_ID = '641db9a4acb2e11c3855cff4';
export const WORKSTYLE_SURVEY_ID = '641a3dee4c63ab06342f593e';
export const PERSONALITY_SURVEY_ID = '641b6f9f2ac34e1617f92fc0';

export async function fetchWorkStyle() {
  try {
    return await fetchWrapper(`${SURVEYS}/641a3dee4c63ab06342f593e`);
  } catch (e) {
    console.log('Error', e);
  }
}

export async function fetchTeamWorkSkill() {
  try {
    return await fetchWrapper(`${SURVEYS}/641db9a4acb2e11c3855cff4`);
  } catch (e) {
    console.log('Error', e);
  }
}

export async function fetchPersonalityTrait() {
  try {
    return await fetchWrapper(`${SURVEYS}/641db9a4acb2e11c3855cff4`);
  } catch (e) {
    console.log('Error', e);
  }
}


export async function fetchCreateSurveyResults(surveyId, surveyResults) {
  try {
    console.log(SURVEYS);
    return await fetchWrapper(`${SURVEYS}/${surveyId}/responses`, {
      method: 'POST',
      body: JSON.stringify(surveyResults),
    });
  } catch (e) {
    console.log('Error', e);
  }
}

export async function fetchSurveyResults(surveyId) {
  try {
    const account = msalInstance?.getActiveAccount();
    return await fetchWrapper(
      `${SURVEYS}/users/${account?.idTokenClaims?.oid}/results/${surveyId}`
    );
  } catch (e) {
    console.log('Error', e);
  }
}
