import { createSlice } from '@reduxjs/toolkit';
import { FETCH_TEAMWORK, FETCH_WORKSTYLE } from './type';
import { loadPersonality, loadTeamWorkSkill, loadWorkStyle, personalityDetails } from './action';

const initialState = {
  teamwork: null,
  workstyle: null,
  personality: null,
  personalityShowDetails: null
};

const reducer = createSlice({
  name: 'survey',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadTeamWorkSkill.fulfilled, (state, action) => {
        state.teamwork = action.payload;
      })
      .addCase(loadWorkStyle.fulfilled, (state, action) => {
        state.workstyle = action.payload;
      })
      .addCase(loadPersonality.fulfilled, (state, action) => {
        state.personality = action.payload;
      })
      .addCase(personalityDetails.fulfilled, (state, action) => {
        state.personalityShowDetails = action.payload;
      });
  },
});

export default reducer.reducer;
