/* eslint-disable no-unused-vars */
import type { ReactElement, ReactNode } from 'react';
import { Suspense } from 'react';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import { ChakraProvider } from '@chakra-ui/react';
import { theme } from 'hugga-reactjs-front-library';
import { appWithTranslation } from 'next-i18next';
import { FullscreenLoader } from '../shared/components/FullscreenLoader/FullscreenLoader';
import { wrapper } from '../store/store';
import { Provider as ReduxProvider } from 'react-redux';
import { MsalProvider } from '@azure/msal-react';
import type { EventMessage } from '@azure/msal-browser';
import { EventType } from '@azure/msal-browser';
import { AuthProvider } from '@features/Auth/context';
import { msalInstance } from '@features/Auth/authConfig';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import '@shared/components/SurveyJsPlugins/css/hugga.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      staleTime: 20000,
    },
  },
});

if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (
    (event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
      event.eventType === EventType.SSO_SILENT_SUCCESS) &&
    event.payload
  ) {
    // @ts-expect-error: assign of type any
    msalInstance.setActiveAccount(event.payload.account);
  }
});

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  const { store } = wrapper.useWrappedStore(pageProps);
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <ChakraProvider
      theme={theme.main.light}
      toastOptions={{ defaultOptions: { position: 'top' } }}
    >
      <QueryClientProvider client={queryClient}>
        <MsalProvider instance={msalInstance}>
          <AuthProvider>
            <ReduxProvider store={store}>
              <Suspense fallback={<FullscreenLoader />}>
                {getLayout(<Component {...pageProps} />)}
              </Suspense>
            </ReduxProvider>
          </AuthProvider>
        </MsalProvider>
      </QueryClientProvider>
    </ChakraProvider>
  );
};

export default appWithTranslation(App);
