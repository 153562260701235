/* eslint-disable no-console */
import fetchWrapper from './fetch-wrapper';
import { PERSONALITIES_RESULTS } from '@src/constants';

export async function fetchPersonalitiesResult(key) {
  try {
    return await fetchWrapper(`${PERSONALITIES_RESULTS}/${key}`);
  } catch (e) {
    console.log('Error', e);
  }
}