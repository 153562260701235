import { SHOW_NOTIFICATION, HIDE_NOTIFICATION } from './type';

const initialState = {
  activeNotification: null,
  type: 'info',
  title: '',
  description: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_NOTIFICATION:
      return {
        ...state,
        activeNotification: true,
        type: action.payload.type,
        title: action.payload.title,
        description: action.payload.description,
      };
    case HIDE_NOTIFICATION:
      return {
        ...state,
        activeNotification: false,
        type: 'info',
        title: '',
        description: '',
      };
    default:
      return state;
  }
}
