import { UPDATE_SURVEY, FETCH_TEAMWORK, FETCH_WORKSTYLE, FETCH_PERSONALITY, FETCH_PERSONALITY_DETAILS } from './type';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchCreateSurveyResults,
  fetchSurveyResults,
  fetchWorkStyle,
} from '../../repository/surveys';
import {
  fetchPersonalityResult
} from '../../repository/personality';

import {
  fetchPersonalitiesResult
} from '../../repository/skill';

export const createSurveyResults = createAsyncThunk(UPDATE_SURVEY, async (payload) => {
  const response = await fetchCreateSurveyResults(payload.surveyId, payload.payload);
  return response;
});

export const loadTeamWorkSkill = createAsyncThunk(FETCH_TEAMWORK, async (payload) => {
  const teamwork = await fetchSurveyResults(payload.surveyId);
  return teamwork;
});

export const loadWorkStyle = createAsyncThunk(FETCH_WORKSTYLE, async (payload) => {
  const workstyle = await fetchSurveyResults(payload.surveyId);
  return workstyle;
});

export const loadPersonality = createAsyncThunk(FETCH_PERSONALITY, async (payload) => {
  const personality = await fetchSurveyResults(payload.surveyId);
  return personality;
});

export const personalityDetails = createAsyncThunk(FETCH_PERSONALITY_DETAILS, async (payload) => {
  
  const personality = await fetchSurveyResults(payload.surveyId);
  if(personality?.surveyResult?.Profile === undefined) return undefined;
  const skill = await fetchPersonalitiesResult(personality?.surveyResult?.Profile)
  const details = await fetchPersonalityResult(personality?.surveyResult?.Profile)
  return personality?.surveyResult ? {...details, skill} : undefined;
});