// const _fetch = typeof window === 'undefined' ? require('node-fetch') : window.fetch;
// import _fetch from 'node-fetch';
import { msalInstance } from '@features/Auth/authConfig';

async function getAccessToken() {
  const accessToken = await msalInstance.acquireTokenSilent({
    scopes: ['https://huggadev.onmicrosoft.com/tasks-api/task.read'],
  });
  return accessToken;
}

export default async function fetchWrapper(url, options = {}) {
  const token = await getAccessToken();
  options.headers = options.headers || {};

  if (options.headers['content-type'] === undefined) {
    options.headers['Content-Type'] = 'application/json';
  } else if (options.headers['content-type'] === null) {
    delete options.headers['content-type'];
  }

  let headers = {
    ...options.headers,
    Authorization: `Bearer ${token?.accessToken}`,
  };

  const requestOptions = {
    ...options,
    headers,
  };

  const response = await fetch(url, requestOptions);

  if (!response.ok) {
    const error = await response.text();
    throw new Error(error);
  } 
  
  if (response.ok && response.status === 204) {
    throw new Error("not_content");
  }

  return response.json();
}
