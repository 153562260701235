import { Flex, Spinner } from '@chakra-ui/react';

const FullscreenLoader = ({ ...restOfProps }) => {
  return (
    <Flex
      zIndex={9}
      backgroundColor="#FFFFFF"
      position="absolute"
      height="100vh"
      top={0}
      bottom={0}
      left={0}
      right={0}
      alignItems="center"
      justifyContent="center"
      {...restOfProps}
    >
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="purple.100"
        color="purple.500"
        size="xl"
      />
    </Flex>
  );
};

export { FullscreenLoader };
