/* eslint-disable no-console */
import fetchWrapper from './fetch-wrapper';
import { PERSONALITY_RESULT } from '@src/constants';

export async function fetchPersonalityResult(key) {
  try {
    return await fetchWrapper(`${PERSONALITY_RESULT}/${key}`);
  } catch (e) {
    console.log('Error', e);
  }
}