import { createStore, applyMiddleware } from 'redux';
import { createWrapper } from 'next-redux-wrapper';
import thunkMiddleware from 'redux-thunk';
import loginReducer from './login/loginReducer';
import registerReducer from './register/registerReducer';
import notificationReducer from './noficiation/notificationReducer';
import profileReducer from './profile/profileReducer';
import surveyReducer from './survey/surveyReducer';
import storage from './syncStorage';
import { combineReducers } from '@reduxjs/toolkit';

const combinedReducer = combineReducers({
  loginReducer,
  registerReducer,
  notificationReducer,
  profileReducer,
  surveyReducer,
});

const bindMiddleware = (middleware) => {
  if (process.env.NODE_ENV !== 'production') {
    const { composeWithDevTools } = require('redux-devtools-extension');
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

const makeStore = ({ isServer }) => {
  if (isServer) {
    return createStore(combinedReducer, bindMiddleware([thunkMiddleware]));
  } else {
    const { persistStore, persistReducer } = require('redux-persist');

    const persistConfig = {
      key: 'nextjs',
      whitelist: ['loginReducer', 'registerReducer', 'profileReducer'],
      storage,
    };

    const persistedReducer = persistReducer(persistConfig, combinedReducer);

    const store = createStore(persistedReducer, bindMiddleware([thunkMiddleware]));

    store.__persistor = persistStore(store);

    return store;
  }
};

export const wrapper = createWrapper(makeStore);
