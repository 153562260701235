import { LOGIN, LOGOUT, SET_LOGIN_PROVIDER, RESET_LOGIN_REDUCER } from './type';

const initialState = {
  loginProvider: '',
  userData: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN:
      return { ...state, userData: action.payload };
    case LOGOUT:
      return { ...state, userData: {}, loginProvider: '' };
    case SET_LOGIN_PROVIDER:
      return { ...state, loginProvider: action.payload };
    case RESET_LOGIN_REDUCER:
      return {
        loginProvider: '',
        userData: {},
      };
    default:
      return state;
  }
}
