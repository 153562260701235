import { CHANGE_AVATAR, DEFINE_ROLE, RESET_PROFILE_REDUCER } from './type';

const initialState = {
  userAvatar: '',
  userPersonalInfo: {},
  // userPersonalInfo object keys: { role }
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_AVATAR:
      return { ...state, userAvatar: action.payload };

    case RESET_PROFILE_REDUCER:
      return {
        userAvatar: '',
        userPersonalInfo: {},
      };

    case DEFINE_ROLE:
      return {
        ...state,
        userPersonalInfo: state.userPersonalInfo,
        role: action.payload,
      };

    default:
      return state;
  }
}
